<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 d-none d-lg-block"
        ></v-img>

        <h2 class="text--primary d-none d-lg-block">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            />

            <!-- tree -->
            <!-- <v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree-2.png"
            ></v-img> -->

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="710"
                class="auth-3d-group"
                :src="require(`@/assets/images/logos/Register.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="align-center auth-bg pt-16  pb-0"
        >
          <v-stepper
            v-model="activeStep"
            flat
            alt-labels
          >
            <!-- Header -->
            <v-stepper-header>
              <!-- Header: Step 1 -->
              <v-stepper-step
                :complete="activeStep > 1"
                step="1"
              >
                <div class="d-flex flex-column text-center text-no-wrap">
                  <span class="text--primary text-sm font-weight-semibold">{{ $t('register_for_free') }}</span>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- Header: Step 2 -->
              <v-stepper-step
                :complete="activeStep > 2"
                step="2"
              >
                <div class="d-flex flex-column text-center text-no-wrap">
                  <span class="text--primary text-sm font-weight-semibold">{{ $t('package') }}</span>
                </div>
              </v-stepper-step>

              <!-- <v-divider></v-divider> -->

              <!-- Header: Step 3 -->
              <!-- <v-stepper-step step="3">
                <div class="d-flex flex-column text-center text-no-wrap">
                  <span class="text--primary text-sm font-weight-semibold">{{ $t('confirm_payment') }}</span>
                </div>
              </v-stepper-step> -->
            </v-stepper-header>

            <!-- Stepper Items -->
            <v-stepper-items>
              <!-- Stepper Content: Step 1 -->
              <v-stepper-content step="1">
                <!-- login form -->

                <v-card-text>
                  <v-alert
                    v-if="errorMessages"
                    text
                    color="error"
                  >
                    {{ $t(errorMessages) }}  !
                  </v-alert>
                  <v-form
                    ref="registerForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-model="shop_name"
                      outlined
                      dense
                      :label="$t('shop_name')"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>
                    <v-text-field
                      v-model="shop_license"
                      outlined
                      dense
                      :label="$t('numberLicenses')"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>
                    <v-text-field
                      v-model="username"
                      outlined
                      dense
                      label="Username"
                      :rules="[validators.required,validators.usernameValidator]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>
                    <v-text-field
                      v-model="password"
                      outlined
                      dense
                      :type="isPasswordVisible ? 'text' : 'password'"
                      :label="$t('password')"
                      :rules="[validators.required]"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-6"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>
                    <v-text-field
                      v-model="password_confirm"
                      outlined
                      dense
                      :type="isPasswordVisible ? 'text' : 'password'"
                      :label="$t('confirm_password')"
                      :rules="[validators.required]"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-6"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>
                    <v-text-field
                      v-model="fullname"
                      outlined
                      dense
                      :label="$t('firstname_lastname')"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>
                    <v-text-field
                      v-model="email"
                      outlined
                      dense
                      :error-messages="emailMessageValdation"
                      :rules="[validators.required, validators.emailValidator]"
                      :label="$t('email')"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>
                    <v-text-field
                      v-model="tel"
                      outlined
                      dense
                      :error-messages="telMessageValdation"
                      :label="$t('tel_contact')"
                      :rules="[validators.required,validators.telValidator]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>
                    <v-checkbox
                      v-model="accept"
                      hide-details
                      readonly
                      :rules="[validators.required]"
                      class="mt-0"
                    >
                      <template #label>
                        <div class="d-flex align-center flex-wrap">
                          <span class="me-2">{{ $t('accept_privacy') }}</span> <span
                            class="primary--text"
                            style="cursor: pointer;"
                            @click="dialog = true"
                          > {{ $t('read_the_agreement') }}</span>
                        </div>
                      </template>
                    </v-checkbox>

                    <v-btn
                      block
                      color="primary"
                      class="mt-6"
                      :loading="loading"
                      :disabled="!accept || loading"
                      type="submit"
                    >
                      {{ $t('register') }}
                    </v-btn>
                  </v-form>
                </v-card-text>
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2">
                    {{ $t('have an account') }}
                  </p>
                  <router-link :to="{name:'auth-login'}">
                    {{ $t('login') }}
                  </router-link>
                </v-card-text>
              </v-stepper-content>
              <v-stepper-content step="2">
                <Packages @onSelect="registerSend" />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="isShowOTP"
      persistent
      width="400"
    >
      <v-card>
        <v-form
          ref="formOTP"
          @submit.prevent="checkOpt"
        >
          <v-card-text class="pt-6">
            <v-text-field
              v-model="otp"
              dense
              :rules="[validators.required]"
              outlined
              :error-messages="messageOTP"
              label="รหัส OTP"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="info"
              :loading="statusDisable"
              :disabled="statusDisable"
              @click="sendOTP"
            >
              {{ $t('send_again') }}
              <template v-slot:loader>
                <span>{{ $t('will_be_send_again_in') }}{{ timeer }}</span>
              </template>
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              :loading="checkOTPLoading"
              :disabled="checkOTPLoading || !otp"
              type="submit"
            >
              {{ $t('confirm') }} OTP
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              dense
              @click="isShowOTP = false"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
      max-width="900"
    >
      <v-card>
        <v-card-title v-if="$i18n.locale == 'th'">
          {{ $t("privacy") }} (Terms Of Use)
        </v-card-title>
        <v-card-title v-if="$i18n.locale == 'en'">
          Terms Of Use
        </v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text v-if="$i18n.locale == 'th'">
          <div
            class="px-5"
          >
            <h4 class="text-center mb-5">
              เงื่อนไขการใช้บริการฉบับนี้มีวัตถุประสงค์เพื่อแจ้งถึงสิทธิและหน้าที่ตามกฏหมายระหว่าง “เรา” “APSTH (บ.เอพีเอส ทีเอช จำกัด)” ในฐานะ “ผู้ให้บริการ” และ “ท่าน” ในฐานะ “ผู้ใช้บริการ” ดังนั้น โปรดอ่านรายละเอียดให้ครบถ้วนเพื่อประโยชน์ในการรับบริการของท่าน
            </h4>

            <h3 class="mb-5">
              1. การเก็บรวบรวมข้อมูลส่วนบุคคล
            </h3>
            <li>
              “ข้อตกลง” คือ เงื่อนไขการใช้บริการ, สัญญา, ภายใต้ข้อกำหนด<a
                href="https://apsth.com/privacy-policy.html"
                target="_blank"
              > นโยบายความเป็นส่วนตัว (Privacy Policy)</a>
            </li>
            <li>“คู่สัญญา” คือ APSTH (บ.เอพีเอส ทีเอช จำกัด) และ ผู้ใช้บริการที่สมัครเข้าใช้บริการ</li>
            <li>“ค่าบริการ” คือ ค่าบริการรายปีที่ผู้ใช้บริการมีหน้าที่ต้องชำระตามรอบที่กำหนดตามสัญญา ตามราคาที่ปรากฏในใบเสนอราคา</li>
            <li>“ข้อมูล” คือ ข้อมูลใดๆที่ผู้ใช้บริการหรือผู้ที่ได้รับมอบอำนาจจากผู้ใช้บริการให้ทำการแทนได้ไว้ให้แก่ทาง APSTH (บ.เอพีเอส ทีเอช จำกัด)</li>
            <li>“ข้อมูลลับ” คือ ข้อมูลต่างๆที่คู่สัญญาได้ให้ไว้แก่กัน ไม่ว่าจะโดยทางวาจา, ลายลักษณ์อักษร หรือข้อมูลอิเลคทรอนิคส์ แต่ไม่รวมถึงข้อมูลใดๆ ที่ได้ถูกเผยแพร่สู่สาธารณเป็นที่เรียบร้อยแล้ว</li>
            <li>“การให้บริการ” คือ การให้บริการซอฟต์แวร์ ระบบ หรือโปรแกรมคลินิก APS Platform ออนไลน์ ที่เหมาะสำหรับ คลินิกความงาม คลินิกเฉพาะทาง คลินิกเวชกรรม คลินิกทั่วไป คลินิกพยาบาลผดุงครรภ์ คลินิกกายภาพบำบัด คลินิกแพทย์แผนไทย-จีน คลินิกอายุรกรรม คลินิกแม่และเด็ก คลินิกบําบัดยาเสพติด เหมาะกับทุกคลินิก</li>
            <li>“ผู้ใช้งานในระบบ” คือ บุคคล หรือบริษัทอื่นใด ที่มิใช่ผู้ใช้บริการที่เป็นคู่สัญญากับ APSTH (บ.เอพีเอส ทีเอช จำกัด) เว้นแต่ได้รับมอบอำนาจจากผู้ใช้บริการ ให้สามารถเข้าใช้งานในนามของตัวผู้ใช้บริการ</li>
            <li>“ผู้ใช้บริการ” คือ ผู้ใช้บริการผู้เป็นคู่สัญญากับ APSTH (บ.เอพีเอส ทีเอช จำกัด) ไม่รวมถึง ผู้ใช้งานในระบบ</li>
            <li>“คู่สัญญา” คือ APSTH (บ.เอพีเอส ทีเอช จำกัด) และ ผู้ใช้บริการที่สมัครเข้าใช้บริการ</li>
            <li class="mb-5">
              “เว็บไซต์” คือ <a
                href="https://apsth.com"
                target="_blank"
              >www.apsth.com</a>
            </li>

            <h3 class="mb-5">
              2. การสมัครใช้งาน
            </h3>
            <li>ผู้ใช้งานที่จะใช้บริการต้องมีอายุ 18 ปี บริบูรณ์ขึ้นไป</li>
            <li>ผู้ใช้งานจะต้องรับผิดชอบดูแล username และรหัสผ่าน เพื่อความปลอดภัยของระบบ</li>
            <li>ผู้ใช้งานจะต้องไม่ทำการคัดลอกเนื้อหา, ดัดแปลงแก้ไข หรือนำไปจำหน่ายต่อบุคคลอื่น</li>
            <li>ผู้ใช้งานยอมรับให้ APSTH (บ.เอพีเอส ทีเอช จำกัด) สามารถแก้ไขรายการข้อมูลที่ส่งเข้าระบบได้</li>
            <li>ผู้ใช้งานจะไม่ทำการหรือพยายามเจาะระบบข้อมูล หรือเขียนรหัสที่เป็นภัยต่อระบบการทำงานของคอมพิวเตอร์</li>
            <li>APSTH (บ.เอพีเอส ทีเอช จำกัด) ไม่รับประกันว่าระบบ Clinic จะไม่ขาดช่วง, หยุดซ่อมบำรุง, หรือปราศจากข้อผิดพลาด</li>
            <li>APSTH (บ.เอพีเอส ทีเอช จำกัด) จะไม่รับผิดชอบต่อการสูญหายของข้อมูล ไม่ว่าในกรณีใดๆ ก็ตาม</li>
            <li class="mb-5">
              APSTH (บ.เอพีเอส ทีเอช จำกัด) สงวนสิทธิ์ในการลบ Account ของผู้ใช้งานบางรายที่ทำผิดกฎ หรือละเมิดสิทธิ์ทางปัญญา หรือทำให้เสียชื่อเสียง
              โดยไม่ต้องแจ้งล่วงหน้าให้ทราบ
            </li>

            <h3 class="mb-5">
              3. การต่อสัญญา
            </h3>
            <li class="mb-5">
              การต่อสัญญา ระยะเวลาในการใช้บริการแพ็กเกจของ <a
                href="https://apsth.com/index.html#price"
                target="_blank"
              >www.apsth.com</a> จะกำหนดไว้ที่เอกสารยืนยันการสั่งซื้อหรือใบเสนอราคาเท่านั้น โดยเมื่อใกล้วันสิ้นสุดสัญญา APSTH (บ.เอพีเอส ทีเอช จำกัด) จะทำการแจ้งเตือนไปยังผู้ใช้บริการก่อนสิ้นสุดระยะเวลาในการใช้บริการ
            </li>

            <h3 class="mb-5">
              4. การเลิกสัญญา
            </h3>
            <li class="mb-5">
              การเลิกสัญญา ผู้ใช้บริการมีสิทธิเลิกสัญญาได้โดย การส่งคำขอเลิกใช้งานมายัง apsth456@gmail.com การยื่นคำขอดังกล่าวจะ จะมีผลในระยะเวลาการใช้บริการในวันสุดท้ายของสัญญาดังกล่าว ในกรณีที่ท่านมีความประสงค์จะเลิกสัญญาโดยให้มีผลในทันที (ก่อนสิ้นสุดระยะเวลาการใช้บริการ) จะไม่คืนเงินค่าบริการที่ท่านได้ชำระไว้แล้วไม่ว่ากรณีใดๆ
            </li>

            <h3 class="mb-5">
              5. การซื้อแพ็คเกจ
            </h3>
            <li>
              ผู้ใช้งานที่สมัครใหม่จะได้ทดลองใช้งานฟรี ตามจำนวนวันที่ APSTH (บ.เอพีเอส ทีเอช จำกัด) กำหนดให้ ก่อนการตัดสินใจใช้งานจริง
              โดยไม่สามารถเพื่มจำนวนวันได้อีก
            </li>
            <li>
              การชำระเงินจะเป็นการชำระเงินก่อนใช้งาน และเมื่อผู้ใช้บริการชำระเงินตามจำนวนที่ต้องการแล้ว APSTH (บ.เอพีเอส ทีเอช จำกัด)
              จะเพิ่มวันตามจำนวนเงินที่โอนโดยทาง APSTH (บ.เอพีเอส ทีเอช จำกัด) จะไม่เปลี่ยนแปลงภายหลังจนกว่าจะหมดอายุ
            </li>
            <li>ผู้ใช้งานที่เคยสมัครแล้วจะไม่ได้รับโปรโมชั่นใหม่ที่ออกมาหลังจากวันที่สมัคร</li>
            <li class="mb-5">
              ผู้ใช้งานที่เคยสมัครแล้วจะไม่สามารถสมัครซํ้าได้อีก หรือ ไม่สามารถเปลี่ยนชื่อ username
              เพื่อรับการทดลองฟรี-โปรโมชั่นอื่นๆ
            </li>

            <h3 class="mb-5">
              6. การคืนเงิน/การเปลี่ยนแปลงบริการ
            </h3>
            <li>APSTH (บ.เอพีเอส ทีเอช จำกัด) ไม่มีนโยบายในการคืนเงิน สำหรับค่าแพ็คเก็จที่ไดซื้อไปแล้ว</li>
            <li>APSTH (บ.เอพีเอส ทีเอช จำกัด) ขอสงวนสิทธิ์ในการเปลี่ยนแปลงราคาโดยไม่ต้องแจ้งให้ทราบล่วงหน้า</li>
            <li class="mb-5">
              ในกรณีเปลี่ยนแปลงค่าบริการ จะมีการแจ้งเตือนให้ผู้ใช้งาน (ลูกค้าเก่า) ทราบล่วงหน้า 30 วันทุกครั้ง และ
              สำหรับผู้ใช้งานที่ซื้อแพ็คเก็จระยะยาวจะไม่มีผลกระทบกับจำนวนวันที่ได้ซื้อก่อนหน้านี้
            </li>

            <h3 class="mb-5">
              7. การโอนสิทธิ
            </h3>
            <li class="mb-5">
              APSTH (บ.เอพีเอส ทีเอช จำกัด) ไม่อนุญาตให้ท่านโอนสิทธิและหน้าที่ที่เกิดขึ้นที่เกิดขึ้นภายใต้สัญญา ให้แก่บุคคลอื่นโดยมิได้รับความยินยอมเป็นลายลักษณ์อักษรจากทาง APSTH (บ.เอพีเอส ทีเอช จำกัด) เท่านั้น
            </li>
          </div>
        </v-card-text>
        <v-card-text v-if="$i18n.locale == 'en'">
          <div class="bootbox-body">
            <div
              class="px-5"
            >
              <h4 class="mb-5">
                These Terms of Service are intended to inform the legal rights and obligations between "us" and
                "APSTH".
                (APS TH Ltd. Partnership) ”as“ Service Provider ”and“ You ”as“ User ”.
                Please read the details completely for your benefit.
              </h4>

              <h3 class="mb-5">
                1. Collection of personal information
              </h3>
              <li>
                “Agreement” is a term of service, a contract, subject to the terms <a
                  href="https://apsth.com/en/privacy-policy.html"
                  target="_blank"
                >
                  Privacy Policy </a>
              </li>
              <li> The “counterparty” is APSTH (APSTH LP) and the user who subscribes to the service </li>
              <li>
                “Service Fee” is the annual service fee that the user is obliged to pay according to the specified
                cycle according to the contract.
                According to the price shown in the quotation
              </li>
              <li>
                "Information" is
                Any information that the user or an authorized person from the service user provides to APSTH.
                (AP STH Ltd., Part.)
              </li>
              <li>
                “Confidential information” is information that the parties have provided to each other. Whether
                verbally, in writing
                Or electronic data But does not include any information Which has been published to the public
                already
              </li>
              <li>
                “Service” is the provision of an online APS Platform clinic software, system or program suitable
                for
                Beauty clinic Specialized clinic Clinical Medicine Clinic, General Clinic, Midwifery Clinic Physical
                Therapy Clinic
                Thai-Chinese Medicine Clinic Internal Medicine Clinic Mother and child clinic Drug Treatment Clinic
                Suitable for all clinics
              </li>
              <li>
                "System user" is any other person or company. That is not a service user who is a party to APSTH
                (APS TH Partnership) unless authorized by the user To be able to use the service on behalf of the
                user
              </li>
              <li>
                “Subscriber” is the subscriber who is a contract party to APSTH (APSTH LP), excluding
                Users on the system
              </li>
              <li> The “counterparty” is APSTH (APSTH LP) and the user who subscribes to the service </li>
              <li class="mb-5 ">
                " Website "is <a
                  href="https://apsth.com/en/index.html"
                  target="_blank"
                > www.apsth.com </a>
              </li>
              .

              <h3 class="mb-5">
                2. Subscription
              </h3>
              <li> The user must be 18 years of age or older. </li>
              <li> The user is responsible for the system security username and password. </li>
              <li> Users must not copy, modify, modify or distribute content to third parties. </li>
              <li>
                The user accepts APSTH (APSTH Ltd., Part., Ltd.) to be able to edit the list of information sent to
                the system.
              </li>
              <li>
                The user will not attempt to hack or hack. Or write code that is harmful to the computer operation
                system
              </li>
              <li>
                APSTH (APSTH Ltd., Part.) does not guarantee that the Clinic system will not be interrupted,
                Or error-free
              </li>
              <li> APSTH (APSTH LP) is not responsible for any data loss. Under any circumstances </li>
              <li class="mb-5">
                APSTH (APSTH LP) reserves the right to delete the Account.
                Of some users who violate the rules Or violate intellectual rights Or defame
                Without prior notice
              </li>

              <h3 class="mb-5">
                3. Renewal of contract
              </h3>
              <li class="mb-5 ">
                Contract extension. Package period of <a
                  href="https://apsth.com/en/index.html#price"
                  target="_blank"
                > www.apsth.com </a>
                Will be stipulated on the order confirmation document or quotation only. Near the end of APSTH
                contract
                (APSTH Partnership) will send a notification to the user before the end of the service period
              </li>

              <h3 class="mb-5">
                4. Termination of contract
              </h3>
              <li class="mb-5 ">
                Termination of contract. Subscriber has the right to terminate the
                contract by Sending a termination request to
                apsth456@gmail.com Such application will It will be effective during the service period on the last
                day of the said contract.
                In the event that you wish to terminate the contract with immediate effect (Before the end of the
                service period)
                The service you have paid will not be refunded under any circumstances.
              </li>

              <h3 class="mb-5">
                5. Purchasing packages
              </h3>
              <li>
                New users will get a free trial. According to the number of days that APSTH (APSTH
                Before deciding to actually use
                Without being able to add more days
              </li>
              <li>
                The payment will be payment before use. And when the user pays the required amount, APSTH
                (AP STH Ltd., Part.)
                The date will be added according to the amount transferred by APSTH (APSTH Partnership) will not
                change later until expiration.
              </li>
              <li>
                Users who have already signed up will not receive any new promotions released after the
                subscription date.
              </li>
              <li class="mb-5 ">
                Users who have already signed up may not be able to apply again, nor can
                they change their name.
                username
                To receive a free trial - other promotions
              </li>

              <h3 class="mb-5">
                6. Refund / Change of Service
              </h3>
              <li>
                APSTH (APS TH Partnership) has no refund policy. For packages that have already been purchased
              </li>
              <li> APSTH (APSTH LP) reserves the right to change prices without prior notice. </li>
              <li class="mb-5 ">
                In case of service charge change There will be a notification to users
                (existing customers).
                Know every 30 days in advance and
                For users who have purchased a long-term package, it will not affect the number of days previously
                purchased.
              </li>

              <h3 class="mb-5">
                7. Assignment
              </h3>
              <li class="mb-5">
                APSTH (APS TH LTD., Part.)
                You are not permitted to assign the rights and obligations arising out of under the contract.
                To other persons without the written consent of APSTH (APSTH Partnership Ltd.) only
              </li>
            </div>
          </div>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="(accept = true), (dialog = false)"
          >
            {{ $t("accept") }}
          </v-btn>
          <v-btn
            color="error"
            outlined
            @click="(accept = false), (dialog = false)"
          >
            {{ $t("not_accept") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { useRouter } from '@core/utils'
// eslint-disable-next-line object-curly-newline
import { alphaValidator, emailValidator, passwordValidator, required, telValidator, usernameValidator } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { ref } from '@vue/composition-api'
import validation from '@/api/validation'
import register from '@/api/register'
import { i18n } from '@/plugins/i18n'
import checkRegister from '@/api/checkRegister'
import Packages from './Packages.vue'

export default {
  components: {
    Packages,
  },
  setup() {
    // Template Refs
    const registerForm = ref(null)
    const { router } = useRouter()
    const dialog = ref(false)
    const otp = ref('')
    const shop_name = ref('')
    const shop_license = ref('')
    const username = ref('')
    const password = ref('')
    const password_confirm = ref('')
    const fullname = ref('')
    const tel = ref('')
    const email = ref('')
    const accept = ref(false)
    const activeStep = ref(1)
    const timeer = ref(30)
    const telMessageValdation = ref('')
    const emailMessageValdation = ref('')
    const telMessageValdationOk = ref('')
    const isPasswordVisible = ref(false)
    const isShowOTP = ref(false)
    const errorMessages = ref('')
    const statusDisable = ref(false)
    const loading = ref(false)
    const checkOTPLoading = ref(false)
    const formOTP = ref(null)
    const messageOTP = ref('')
    const iframePaySulution = ref('')
    const { checkTelRegister, checkEmail } = validation
    const { registerSendOTP, registerOTPChecking, registerAdd } = register

    checkRegister.checkRegisterGet().then(res => {
      if (res == '0') {
        router.push({ name: 'auth-login' })
      }
    })
    const checkTel = () => {
      emailMessageValdation.value = ''
      telMessageValdation.value = ''
      checkTelRegister(tel.value).then(res => {
        if (res == 1) {
          telMessageValdation.value = i18n.t('this_tel_is_already_in_use')
          loading.value = false
        } else {
          emailChecking()
        }
      })
    }

    const emailChecking = () => {
      checkEmail(email.value).then(res => {
        if (!res.response) {
          emailMessageValdation.value = i18n.t('This email is already used.')
          loading.value = false
        } else {
          sendOTP()
        }
      })
    }

    const countdownTimer = () => {
      timeer.value = 30
      let countdown = 30
      const countdownInterval = setInterval(() => {
        countdown--
        if (countdown === 0) {
          clearInterval(countdownInterval)
          statusDisable.value = false
        }
        timeer.value = countdown
      }, 1000)
    }

    const sendOTP = () => {
      registerSendOTP({
        shop_name: shop_name.value,
        shop_license: shop_license.value,
        username: username.value,
        password: password.value,
        password_confirm: password_confirm.value,
        fullname: fullname.value,
        email: email.value,
        tel: tel.value,
        language: i18n.locale,
        accept: accept.value ? 1 : '0',
      }).then(res => {
        if (res.response) {
          countdownTimer()
          statusDisable.value = true
          isShowOTP.value = true
          errorMessages.value = ''
          loading.value = false
        } else {
          errorMessages.value = res.message
          statusDisable.value = false
          isShowOTP.value = false
          loading.value = false
        }
      })
    }

    const handleFormSubmit = () => {
      const isFormValid = registerForm.value.validate()
      if (!isFormValid) return
      loading.value = true
      if (password.value !== password_confirm.value) {
        errorMessages.value = 'confirm_password_not_matching'
        loading.value = false
      } else {
        checkTel()
      }
    }

    const checkOpt = () => {
      messageOTP.value = null
      checkOTPLoading.value = true
      registerOTPChecking({
        tel: tel.value,
        otp: otp.value,
      }).then(res => {
        if (res.response) {
          isShowOTP.value = false
          activeStep.value = 2
        } else {
          messageOTP.value = res.data == 3 ? i18n.t('optNotcorrect') : null
          loading.value = false
          checkOTPLoading.value = false
        }
      })
    }

    const registerSend = (id, type) => {
      registerAdd({
        shop_name: shop_name.value,
        shop_license: shop_license.value,
        username: username.value,
        password: password.value,
        password_confirm: password_confirm.value,
        fullname: fullname.value,
        email: email.value,
        tel: tel.value,
        language: i18n.locale,
        accept: accept.value ? 1 : '0',
        otp: otp.value,
        packageshop_id: id,
        packageshop_type: type,
      }).then(res => {
        if (res.response) {
          // iframePaySulution.value = res.data.data.confirmLink
          // activeStep.value = 3
          window.location.href = res.data.data.confirmLink

          // router.push({ name: 'auth-login' })
        } else {
          errorMessages.value = res.data
          loading.value = false
          checkOTPLoading.value = false
        }
      })
    }

    return {
      checkOpt,
      telMessageValdation,
      isPasswordVisible,
      username,
      shop_name,
      statusDisable,
      shop_license,
      password,
      iframePaySulution,
      password_confirm,
      activeStep,
      fullname,
      formOTP,
      tel,
      email,
      required,
      accept,
      errorMessages,
      handleFormSubmit,
      isShowOTP,
      timeer,
      emailMessageValdation,
      loading,
      telMessageValdationOk,
      messageOTP,
      registerSend,
      checkOTPLoading,
      dialog,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
        passwordValidator,
        alphaValidator,
        telValidator,
        usernameValidator,
      },
      sendOTP,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      otp,

      // Template Refs
      registerForm,
      checkTel,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';

li{
  padding-left:15px ;
}

</style>
