<template>
  <v-card
    flat
    class='px-2'>
    <div class='d-flex justify-end'>
      <v-switch
        v-model='type'
        :label="`${$t('monthly')} / ${$t('yearly')}`"
        hide-details
        :true-value='1'
        :false-value='2'
      >
      </v-switch>
    </div>
    <v-radio-group
      v-model='activeShop'
    >
      <v-radio
        v-for='(item,index) in packageList'
        :key='index'

        :value='item.packageshop_id'
      >
        <template v-slot:label>
          <v-card
            width='100%'
          >
            <v-card-title class='py-2'>
              {{ item.packageshop_name }}
              <v-spacer></v-spacer>
              <span class='error--text'>
                {{ type == '2' ? item.packageshop_cost_mounth : item.packageshop_cost_year | formatPrice
                }}
              </span>
            </v-card-title>
            <v-card-text class='pb-2'>
              <p class='ml-3 my-0 primary--text font-weight-semibold'>
                - {{ $t('can_be_used') }} {{ +item.packageshop_useshop + 1 }} {{ $t('branch') }}
              </p>
              <p class='ml-3 my-0 primary--text font-weight-semibold'>
                - {{ $t('manager') }} {{ item.packageshop_usemanager }} User
              </p>
              <p class='ml-3 my-0 primary--text font-weight-semibold'>
                - {{ $t('doctor') }} {{ item.packageshop_usedoctor }} User
              </p>
              <p class='ml-3 my-0 primary--text font-weight-semibold'>
                - {{ $t('staff') }} {{ item.packageshop_useuser }} User
              </p>
            </v-card-text>
          </v-card>
        </template>
      </v-radio>
    </v-radio-group>
    <div
      align='center'
      class='mb-3'
    >
      {{ $t('package_rate')
      }}<span class='error--text h5 ma-2'>{{
        $t('this_price_total_vat2')
      }}</span>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color='primary'
        block
        :disabled='loading'
        :loading='loading'
        @click="loading=true; $emit('onSelect', activeShop, type)"
      >
        {{ $t('next') }}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import packages from '@/api/packages'
import { ref } from '@vue/composition-api'
import { formatPrice } from '@/plugins/filters'

export default {
  filters: {
    formatPrice,
  },
  setup() {
    const packageList = ref([])
    const activeShop = ref()
    const loading = ref(false)
    const type = ref(2)
    packages.packageShop().then(res => {
      activeShop.value = res[0].packageshop_id
      packageList.value = res
    })

    return {
      packageList,
      activeShop,
      loading,
      type,
    }
  },
}
</script>
