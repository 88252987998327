import { api as api2 } from './mainNode'

const registerSendOTP = async body => {
  const response = await api2
    .post({
      path: 'register/sendsms',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('send OTP  err : ', err)

      return {}
    })

  return response
}
const registerOTPChecking = async body => {
  const response = await api2
    .post({
      path: 'register/checkotp',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('check OTP  err : ', err)

      return {}
    })

  return response
}

const registerAdd = async body => {
  const response = await api2
    .post({
      path: 'paysolutions/register',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('register  err : ', err)

      return {}
    })

  return response
}

export default { registerSendOTP, registerOTPChecking, registerAdd }
